@import "new-design/settings";

@import "new-design/components/tooltip";
@import "new-design/components/progress-bar";
@import "new-design/normalizations";
@import "new-design/typography";
@import "new-design/layout";
@import "new-design/forms";
@import "new-design/fonts";
@import "new-design/buttons";
@import "new-design/tables";
@import "new-design/selectize";

.fill-space {
  flex: 1 0 auto;
}