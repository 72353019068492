@font-face {
  font-family:work sans;
  src:url(/fonts/WorkSans-Regular.woff2) format("woff2");
  font-weight:400;
  font-style:normal;
  font-display:fallback
}
@font-face {
  font-family:work sans;
  src:url(/fonts/WorkSans-Italic.woff2) format("woff2");
  font-weight:400;
  font-style:italic;
  font-display:fallback
}
@font-face {
  font-family:work sans;
  src:url(/fonts/WorkSans-Medium.woff2) format("woff2");
  font-weight:500;
  font-style:normal;
  font-display:fallback
}
@font-face {
  font-family:work sans;
  src:url(/fonts/WorkSans-MediumItalic.woff2) format("woff2");
  font-weight:500;
  font-style:italic;
  font-display:fallback
}
@font-face {
  font-family:work sans;
  src:url(/fonts/WorkSans-Bold.woff2) format("woff2");
  font-weight:700;
  font-style:normal;
  font-display:fallback
}
@font-face {
  font-family:work sans;
  src:url(/fonts/WorkSans-BoldItalic.woff2) format("woff2");
  font-weight:700;
  font-style:italic;
  font-display:fallback
}
@supports (font-variation-settings:normal) {
  @font-face {
    font-family:work sans;
    src:url(/fonts/WorkSans-VariableFont_wght.woff2) format("woff2 supports variations"),
    url(/fonts/WorkSans-VariableFont_wght.woff2) format("woff2-variations");
    font-weight:100 1000;
    font-style:normal;
    font-display:fallback
  }
  @font-face {
    font-family:work sans;
    src:url(/fonts/WorkSans-Italic-VariableFont_wght.woff2) format("woff2 supports variations"),
    url(/fonts/WorkSans-Italic-VariableFont_wght.woff2) format("woff2-variations");
    font-weight:100 1000;
    font-style:italic;
    font-display:fallback
  }
}