
.form-group {
  line-height: 2*@gutter;

  &.mb-0 {
    margin-bottom: 0 !important;
  }
}

.form-control {
  box-shadow: none;
  height: 2*@gutter;
  box-sizing: border-box;
  font-weight: 400 !important;
  font-style: normal !important;
  font-size: 15px !important;
  border-radius: 0 !important;
  color: @colors-text-input;

  &::placeholder {
    color: @colors-text-input-placeholder;
  }
}

.date-form {
  width: 250px;
  position: relative;
  z-index: 2;
  float: left;
  margin-bottom: 0;
  box-shadow: none;
  font-size: inherit;
  box-sizing: border-box;
  padding: 6px 12px;
  color: #555555;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0 4px 4px 0;
  height: 34.4px;
}

.sst-panel {
  .search-field {
    position: absolute;
    height: 36px;
    width: 300px;
    right: @gutter;
    top: @gutter * 0.9;

    .form-control {
      height: 40px;
      font-size: 14px;
      border-radius: 0;
      border: 1px solid #ccc;
      padding-right: 0;
    }

    .input-group-btn {
      position: absolute;
      right: 46px;

      button {
        color: #aaa;
        background: none;
        z-index: 5;
        padding-right: 36px;
        //padding-top: 8px;

        i {
          font-size: 20px;
        }
      }
    }
  }
}

.export_form {
  max-width: 300px;
}

@media screen and (max-width: 767px) {
  .sst-panel .form-control {
    display: none;
  }
  .sst-panel .search-field {
    width: 80%;
  }
}

.input-group-btn {
  height: 2*@gutter;

  & > .btn {
    height: 2*@gutter;
    display: inline-block;
    font-size: @font-size;
  }
}

fieldset legend {
  font-size: 1.5em;
  padding-top: 20px;
  font-family: @font-primary;
  font-weight: 300;
  color: @color-darkest-gray;
}

.form-head {
  margin-top: @gutter;
}

.checkbox {
  label {
    padding-left: 0;
  }
  input[type=checkbox], input[type=radio] {
    opacity: 0;
    height: 1px;
    width: 1px;
    position: absolute;
    top: -200px;

    & + span {
      vertical-align: text-bottom;
      font-size: @font-size * 1.3;
      font-family: 'FontAwesome-SST' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      color: @color-gray;

      &::before {
        display: inline-block;
        content: "\f096";
        width: 1.4em;
      }
    }

    &:checked + span::before {
      color: @colors-primary;
      content: "\f046";
    }
  }
}

.help-block {
  font-size: 0.7em;
  line-height: 1;
}

.form-group {
  .selectize-control {
    height: 2*@gutter;
  }

  .selectize-input {
    display: block;
    .item {
      line-height: 26px;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .selectize-dropdown, .selectize-input, .selectize-input input {
    line-height: 26px;
  }

  &.form-group-date {
    .input-group {
      width: 100%;

      input {
        width: calc(~'100% - 50px');
        float: left;
      }

      .input-group-btn {
        width: 50px;
        float: left;

        > .btn {
          padding-left: 17px;
          padding-right: 17px;
        }
      }
    }
  }
}

.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
  padding-top: 0;
}

.flexbox{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.portal-container {
  width: 87%;
  margin: auto;
}

@media screen and (max-width: 767px) {
  .portal-container {
    width: 95%;
  }
}
