/**
 * Typography
 * base
 */

body {
    font-family: @font-primary;
    font-style:normal;
    font-size:1rem;
    //background: @colors-primary;
}
body,
h1 {
    font-weight:400
}
h1 {
    font-size:50px
}
@media screen and (min-width:414px) {
    h1 {
        font-size:calc(50px + 60.4*(100vw - 414px)/1506)
    }
}
@media screen and (min-width:1920px) {
    h1 {
        font-size:120px
    }
}
@media print,screen and (min-width:769px) {
    h1 {
        font-weight:400
    }
}
h2 {
    font-weight:400;
    font-size:35px
}
@media screen and (min-width:414px) {
    h2 {
        font-size:calc(35px + 29.4*(100vw - 414px)/1506)
    }
}
@media screen and (min-width:1920px) {
    h2 {
        font-size:70px
    }
}


h3 {
    font-size:1.6rem;
    font-weight:400
}

body {
    //color: @color-dark;
    color: @colors-text-primary;
    font-family: @font-primary;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
    font-family: @font-primary;
    color: @colors-primary;

    .colored {
        color: @colors-text-accent;
    }
    &.-no-bottom {
        margin-bottom: 0;
    }
    &.-no-top {
        margin-top: 0;
    }
    &.-bold {
        font-weight: 600;
    }
}

h1 {
    font-size: @gutter * 1.5;
    margin-bottom: @gutter;
}

@media screen and (max-width: 767px) {
    h1 {
        font-size: @gutter * 1.2;
    }
}

h2 {
    font-size: @gutter;
}

p {
    font-size: 1em;
    line-height: @gutter * 1.5;

    &.-no-bottom {
        margin-bottom: 0;
    }

    &.-no-top {
        margin-top: 0;
    }

    &.-intro {
        font-size: 1.2em;
    }
}

p, i, span {
    &.-alert {
        color: @color-alert;
    }
}

abbr {
    border: none;
    border-bottom: 1px dotted;
    text-decoration: none;
}


a {
    color: inherit;
    text-decoration: inherit;
    cursor: pointer;
}

small {
    font-size: .75em;
}

em {
    font-style: italic;
}

.color-gray {
    color:@color-light-gray;
}

h3.notification-heading{
    margin-top: 0;
}

h3.notification-subject{
    margin-top: 0;
}
