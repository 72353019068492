@import url('https://fonts.googleapis.com/css?family=Roboto:300,400');
.tooltip {
  border-radius: 0;
}
.tooltip .tooltip-inner {
  padding: 10px;
  border: 1px solid #cecece;
  background: white;
  color: #0B292D;
}
.tooltip .tooltip-arrow {
  background: white;
  width: 12px;
  height: 12px;
  border-width: 0 !important;
  border-style: solid !important;
  border-bottom-width: 1px !important;
  border-right-width: 1px !important;
  border-color: #cecece !important;
}
.tooltip.in {
  opacity: 1;
}
.tooltip.top .tooltip-arrow,
.tooltip.bottom .tooltip-arrow {
  margin-left: -6px !important;
}
.tooltip.left .tooltip-arrow,
.tooltip.right .tooltip-arrow {
  margin-top: -6px !important;
}
.tooltip.top .tooltip-arrow {
  bottom: -1px;
}
.tooltip.bottom .tooltip-arrow {
  top: -1px;
}
.tooltip.top .tooltip-arrow,
.tooltip.top-left .tooltip-arrow,
.tooltip.top-right .tooltip-arrow {
  -ms-transform: rotate(45deg);
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  /* Safari */
  transform: rotate(45deg);
}
.tooltip.bottom .tooltip-arrow,
.tooltip.bottom-left .tooltip-arrow,
.tooltip.bottom-right .tooltip-arrow {
  -ms-transform: rotate(-135deg);
  /* IE 9 */
  -webkit-transform: rotate(-135deg);
  /* Safari */
  transform: rotate(-135deg);
}
.tooltip.right .tooltip-arrow {
  left: -1px;
  -ms-transform: rotate(135deg);
  /* IE 9 */
  -webkit-transform: rotate(135deg);
  /* Safari */
  transform: rotate(135deg);
}
.tooltip.left .tooltip-arrow {
  right: -1px;
  -ms-transform: rotate(-45deg);
  /* IE 9 */
  -webkit-transform: rotate(-45deg);
  /* Safari */
  transform: rotate(-45deg);
}
.progress {
  height: 30px;
  border-radius: 15px;
}
.progress .progress-bar {
  font-size: 16px;
  line-height: 30px;
  padding: 0 8px;
}
.progress .progress-bar.progress-bar-success {
  background-color: #180124;
}
/**
 * Normalizations
 * base
 */
button,
input,
select,
textarea {
  border-radius: 0;
  color: inherit;
  font-family: work sans, sans-serif;
  font-weight: 400;
  max-width: 100%;
  outline: none;
  padding-bottom: 0.35714286em;
}
button,
input[type=text],
textarea {
  appearance: none;
}
html,
body {
  height: 100%;
  background-color: #ffffff;
  color: #0b292d;
  font-family: work sans, sans-serif;
  font-size: 14px;
}
/**
 * Typography
 * base
 */
body {
  font-family: work sans, sans-serif;
  font-style: normal;
  font-size: 1rem;
}
body,
h1 {
  font-weight: 400;
}
h1 {
  font-size: 50px;
}
@media screen and (min-width: 414px) {
  h1 {
    font-size: calc(37.40664011px);
  }
}
@media screen and (min-width: 1920px) {
  h1 {
    font-size: 120px;
  }
}
@media print, screen and (min-width: 769px) {
  h1 {
    font-weight: 400;
  }
}
h2 {
  font-weight: 400;
  font-size: 35px;
}
@media screen and (min-width: 414px) {
  h2 {
    font-size: calc(28.87011952px);
  }
}
@media screen and (min-width: 1920px) {
  h2 {
    font-size: 70px;
  }
}
h3 {
  font-size: 1.6rem;
  font-weight: 400;
}
body {
  color: #000000;
  font-family: work sans, sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  font-family: work sans, sans-serif;
  color: #180124;
}
h1 .colored,
h2 .colored,
h3 .colored,
h4 .colored,
h5 .colored,
h6 .colored {
  color: #009BFF;
}
h1.-no-bottom,
h2.-no-bottom,
h3.-no-bottom,
h4.-no-bottom,
h5.-no-bottom,
h6.-no-bottom {
  margin-bottom: 0;
}
h1.-no-top,
h2.-no-top,
h3.-no-top,
h4.-no-top,
h5.-no-top,
h6.-no-top {
  margin-top: 0;
}
h1.-bold,
h2.-bold,
h3.-bold,
h4.-bold,
h5.-bold,
h6.-bold {
  font-weight: 600;
}
h1 {
  font-size: 2.14285714em;
  margin-bottom: 1.42857143em;
}
@media screen and (max-width: 767px) {
  h1 {
    font-size: 1.71428571em;
  }
}
h2 {
  font-size: 1.42857143em;
}
p {
  font-size: 1em;
  line-height: 2.14285714em;
}
p.-no-bottom {
  margin-bottom: 0;
}
p.-no-top {
  margin-top: 0;
}
p.-intro {
  font-size: 1.2em;
}
p.-alert,
i.-alert,
span.-alert {
  color: #FE5E46;
}
abbr {
  border: none;
  border-bottom: 1px dotted;
  text-decoration: none;
}
a {
  color: inherit;
  text-decoration: inherit;
  cursor: pointer;
}
small {
  font-size: .75em;
}
em {
  font-style: italic;
}
.color-gray {
  color: #EBEBEB;
}
h3.notification-heading {
  margin-top: 0;
}
h3.notification-subject {
  margin-top: 0;
}
.navbar-header button {
  width: 33px;
  height: 33px;
  cursor: pointer;
  pointer-events: auto;
  z-index: 1;
  float: right;
  background: inherit;
  border: 0;
  padding: 0;
}
.sst-panel {
  position: relative;
  padding: 2.85714286em;
  border-radius: 0;
  box-shadow: 30px 30px 50px #15011f;
}
.sst-panel h2 {
  margin-bottom: 20px;
}
.sst-panel .nav-container {
  position: relative;
  width: 100%;
  display: inline-block;
  vertical-align: middle;
}
.sst-panel .nav-container .tabs-wrapper {
  width: 100%;
  float: left;
  overflow: hidden;
}
.sst-panel .nav-container .tabs-wrapper ul.nav-tabs {
  width: 100%;
  height: 26px;
  border-bottom: 0;
  white-space: nowrap;
  position: relative;
  transition: .5s ease;
}
.sst-panel .nav-container .tabs-wrapper ul.nav-tabs > li {
  float: none;
  display: inline;
}
.sst-panel .nav-container .tabs-wrapper ul.nav-tabs > li > a {
  font-size: 14px;
  padding: 8px 12px 4px;
  color: #BBB3C0;
  border: none;
  display: inline;
}
.sst-panel .nav-container .tabs-wrapper ul.nav-tabs > li > a:hover {
  color: #8F7B9E;
  background: none;
  border: none;
}
.sst-panel .nav-container .tabs-wrapper ul.nav-tabs > li.active a {
  border: none;
  color: #009BFF;
  border-bottom: 2px solid #009BFF;
}
.sst-panel .nav-container button.nav-button {
  position: absolute;
  float: left;
  width: 40px;
  margin: 0;
}
.sst-panel .nav-container button.nav-button.left {
  right: 100%;
}
.sst-panel .nav-container button.nav-button.right {
  left: 100%;
}
.sst-panel .nav-container button.nav-button[disabled] {
  opacity: 0.3;
}
.sst-panel .nav-container button.nav-button i {
  vertical-align: middle;
}
header {
  padding: 15px 0;
}
header .branding-wrapper {
  text-decoration: none;
}
header .branding-wrapper:hover {
  text-decoration: none;
}
header .branding-wrapper .branding-logo {
  max-height: 80px;
  overflow: hidden;
  margin-top: -20px;
}
header .branding-wrapper .branding-text {
  font-size: 32px;
  font-weight: 500;
  color: #2c0648;
}
header ul.nav li {
  color: #9B9B9B;
}
header ul.nav li.dropdown span:first-child {
  padding-right: 15px;
}
header h3 {
  color: #0b292d;
}
@media screen and (max-width: 767px) {
  header ul.nav li.dropdown span:first-child {
    display: none;
  }
}
footer {
  text-align: center;
  padding: 25px 0;
}
[data-letters]:before {
  content: attr(data-letters);
  display: inline-block;
  font-size: 0.8em;
  width: 2.8em;
  height: 2.8em;
  line-height: 2.8em;
  text-align: center;
  border-radius: 50%;
  background: #1E8490;
  vertical-align: middle;
  margin-right: 1em;
  color: white;
}
.sidebar-nav ul {
  list-style-type: none;
  font-weight: 400;
}
.sidebar-nav li {
  padding: 8px 0;
}
.sidebar-nav li a {
  text-decoration: none;
  padding: 0 10px 5px 10px;
}
.sidebar-nav li a i.fa {
  padding-right: 20px;
  display: inline-block;
  text-align: center;
  width: 30px;
  font-size: 16px;
}
.sidebar-nav li a i.fa:active {
  color: #009BFF;
}
.sidebar-nav li a.active {
  color: #009BFF;
  border-bottom: 2px solid #009BFF;
}
.sidebar-nav li a.active i.fa {
  color: #009BFF;
}
.sidebar-nav li a:hover {
  color: #8F7B9E;
}
.sidebar-nav li a:hover i.fa {
  color: #8F7B9E;
}
.sidebar-nav li.header {
  color: #494949;
  font-weight: 500;
}
.sidebar-nav li.header:not(:first-child) {
  padding-top: 25px;
}
.pagehead {
  background-color: #F7F7F7;
  margin-left: -2.85714286em;
  margin-right: -2.85714286em;
  padding: 40px;
}
.pagehead .top-buttons {
  width: 100%;
}
.pagehead .top-buttons a {
  margin: 5px;
}
.pagehead .top-buttons a:first-child {
  margin-left: 0;
}
.pagehead .top-buttons a:last-child {
  margin-right: 0;
}
.pagehead.table-only {
  padding-top: 0;
  padding-bottom: 0;
  height: 0;
  overflow: hidden;
}
/**
 * Avatar sizes
 */
.avatar-l {
  height: 48px;
  width: 48px;
}
.alert {
  border: none;
  border-radius: 0;
}
.form-group {
  line-height: 2.85714286em;
}
.form-group.mb-0 {
  margin-bottom: 0 !important;
}
.form-control {
  box-shadow: none;
  height: 2.85714286em;
  box-sizing: border-box;
  font-weight: 400 !important;
  font-style: normal !important;
  font-size: 15px !important;
  border-radius: 0 !important;
  color: #000000;
}
.form-control::placeholder {
  color: #AFAFAF;
}
.date-form {
  width: 250px;
  position: relative;
  z-index: 2;
  float: left;
  margin-bottom: 0;
  box-shadow: none;
  font-size: inherit;
  box-sizing: border-box;
  padding: 6px 12px;
  color: #555555;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0 4px 4px 0;
  height: 34.4px;
}
.sst-panel .search-field {
  position: absolute;
  height: 36px;
  width: 300px;
  right: 1.42857143em;
  top: 1.28571429em;
}
.sst-panel .search-field .form-control {
  height: 40px;
  font-size: 14px;
  border-radius: 0;
  border: 1px solid #ccc;
  padding-right: 0;
}
.sst-panel .search-field .input-group-btn {
  position: absolute;
  right: 46px;
}
.sst-panel .search-field .input-group-btn button {
  color: #aaa;
  background: none;
  z-index: 5;
  padding-right: 36px;
}
.sst-panel .search-field .input-group-btn button i {
  font-size: 20px;
}
.export_form {
  max-width: 300px;
}
@media screen and (max-width: 767px) {
  .sst-panel .form-control {
    display: none;
  }
  .sst-panel .search-field {
    width: 80%;
  }
}
.input-group-btn {
  height: 2.85714286em;
}
.input-group-btn > .btn {
  height: 2.85714286em;
  display: inline-block;
  font-size: 14px;
}
fieldset legend {
  font-size: 1.5em;
  padding-top: 20px;
  font-family: work sans, sans-serif;
  font-weight: 300;
  color: #494949;
}
.form-head {
  margin-top: 1.42857143em;
}
.checkbox label {
  padding-left: 0;
}
.checkbox input[type=checkbox],
.checkbox input[type=radio] {
  opacity: 0;
  height: 1px;
  width: 1px;
  position: absolute;
  top: -200px;
}
.checkbox input[type=checkbox] + span,
.checkbox input[type=radio] + span {
  vertical-align: text-bottom;
  font-size: 18.2px;
  font-family: 'FontAwesome-SST' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  color: #9B9B9B;
}
.checkbox input[type=checkbox] + span::before,
.checkbox input[type=radio] + span::before {
  display: inline-block;
  content: "\f096";
  width: 1.4em;
}
.checkbox input[type=checkbox]:checked + span::before,
.checkbox input[type=radio]:checked + span::before {
  color: #180124;
  content: "\f046";
}
.help-block {
  font-size: 0.7em;
  line-height: 1;
}
.form-group .selectize-control {
  height: 2.85714286em;
}
.form-group .selectize-input {
  display: block;
}
.form-group .selectize-input .item {
  line-height: 26px;
}
.form-group .selectize-input:focus {
  box-shadow: none;
}
.form-group .selectize-dropdown,
.form-group .selectize-input,
.form-group .selectize-input input {
  line-height: 26px;
}
.form-group.form-group-date .input-group {
  width: 100%;
}
.form-group.form-group-date .input-group input {
  width: calc(100% - 50px);
  float: left;
}
.form-group.form-group-date .input-group .input-group-btn {
  width: 50px;
  float: left;
}
.form-group.form-group-date .input-group .input-group-btn > .btn {
  padding-left: 17px;
  padding-right: 17px;
}
.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
  padding-top: 0;
}
.flexbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.portal-container {
  width: 87%;
  margin: auto;
}
@media screen and (max-width: 767px) {
  .portal-container {
    width: 95%;
  }
}
@font-face {
  font-family: work sans;
  src: url(/fonts/WorkSans-Regular.woff2) format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: work sans;
  src: url(/fonts/WorkSans-Italic.woff2) format("woff2");
  font-weight: 400;
  font-style: italic;
  font-display: fallback;
}
@font-face {
  font-family: work sans;
  src: url(/fonts/WorkSans-Medium.woff2) format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: work sans;
  src: url(/fonts/WorkSans-MediumItalic.woff2) format("woff2");
  font-weight: 500;
  font-style: italic;
  font-display: fallback;
}
@font-face {
  font-family: work sans;
  src: url(/fonts/WorkSans-Bold.woff2) format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: work sans;
  src: url(/fonts/WorkSans-BoldItalic.woff2) format("woff2");
  font-weight: 700;
  font-style: italic;
  font-display: fallback;
}
@supports (font-variation-settings:normal) {
  @font-face {
    font-family: work sans;
    src: url(/fonts/WorkSans-VariableFont_wght.woff2) format("woff2 supports variations"), url(/fonts/WorkSans-VariableFont_wght.woff2) format("woff2-variations");
    font-weight: 100 1000;
    font-style: normal;
    font-display: fallback;
  }
  @font-face {
    font-family: work sans;
    src: url(/fonts/WorkSans-Italic-VariableFont_wght.woff2) format("woff2 supports variations"), url(/fonts/WorkSans-Italic-VariableFont_wght.woff2) format("woff2-variations");
    font-weight: 100 1000;
    font-style: italic;
    font-display: fallback;
  }
}
.btn {
  height: 3rem;
  padding: 0 1.8rem;
  border-radius: 0;
}
.btn-primary {
  display: inline-flex;
  position: relative;
  background-image: linear-gradient(90deg, rgba(128, 0, 255, 0.5), rgba(255, 0, 235, 0.5));
  text-align: center;
  align-items: center;
  vertical-align: middle;
  user-select: none;
  border: none;
  text-decoration: none;
  color: #fff;
  background-color: inherit;
  z-index: 0;
  border-radius: 0;
}
.btn-primary .fa {
  margin-right: 3px;
}
.btn-primary:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(90deg, #8000FF, #FF00EB);
  z-index: -1;
  transition: opacity .5s linear;
  opacity: 1;
}
.btn-primary:focus,
.btn-primary:hover {
  outline: 0;
  color: #ffffff;
  text-decoration: none;
}
.btn-primary:focus:before,
.btn-primary:hover:before {
  opacity: 0;
}
@media print, screen and (min-width: 769px) {
  .btn-primary {
    height: 3rem;
  }
}
.btn-secondary {
  display: inline-flex;
  position: relative;
  text-align: center;
  align-items: center;
  vertical-align: middle;
  user-select: none;
  text-decoration: none;
  color: #FF00EB;
  border: 2px solid #FF00EB;
  background-color: inherit;
  border-radius: 0;
}
.btn-secondary:focus,
.btn-secondary:hover {
  outline: 0;
  border-color: #ffffff;
  color: #ffffff;
  background-color: #2c0648;
  text-decoration: none;
  box-shadow: 0 0 27px 1px #FF00EB;
}
.nav-container button.nav-button {
  text-decoration: none;
  color: #FF00EB !important;
  border: 2px solid #FF00EB !important;
  border-radius: 0;
  padding: inherit;
}
.nav-container button.nav-button:disabled {
  pointer-events: none;
}
.nav-container button.nav-button:focus,
.nav-container button.nav-button:hover {
  outline: 0;
  border-color: #ffffff !important;
  color: #ffffff !important;
  background-color: #2c0648 !important;
  text-decoration: none;
  box-shadow: 0 0 27px 1px #FF00EB;
}
ul .paginate_button.disabled a {
  pointer-events: none;
}
ul .paginate_button a {
  border-radius: 0 !important;
  display: inline-flex;
  position: relative;
  height: 1.8rem;
  padding: 0 1.8rem;
  text-align: center;
  align-items: center;
  vertical-align: middle;
  user-select: none;
  text-decoration: none;
  color: #FF00EB !important;
  border: 2px solid #FF00EB !important;
}
ul .paginate_button a:focus,
ul .paginate_button a:hover {
  outline: 0;
  border-color: #ffffff !important;
  color: #ffffff !important;
  background-color: #2c0648 !important;
  text-decoration: none;
  box-shadow: 0 0 27px 1px #FF00EB;
}
@media print, screen and (min-width: 769px) {
  .btn-secondary {
    height: 3rem;
  }
}
.btn-primary .icon,
.btn-secondary .icon {
  display: inline-block;
  width: 1.6rem;
  margin-left: -1.2rem;
  margin-right: 0.6rem;
}
.btn-primary.dimmed:not(:hover),
.btn-secondary.dimmed:not(:hover) {
  opacity: 0.6;
}
.btn-mt {
  margin-top: 35px;
}
@media print, screen and (min-width: 769px) {
  .btn-mt {
    margin-top: 2rem;
  }
}
.btn-mb {
  margin-bottom: 35px;
}
@media print, screen and (min-width: 769px) {
  .btn-mb {
    margin-bottom: 2rem;
  }
}
table.dataTable {
  margin-top: 0 !important;
  margin-bottom: 30px !important;
  padding-bottom: 10px;
}
table.dataTable > thead > tr > th {
  padding-right: 25px;
  vertical-align: middle;
}
table.dataTable > thead .sorting:after,
table.dataTable > thead .sorting_asc:after,
table.dataTable > thead .sorting_desc:after {
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.6;
}
table.dataTable .dataTables_empty {
  text-align: center;
  opacity: 0.5;
}
table.table a {
  color: #180124;
}
table.table thead {
  background-color: #F7F7F7;
}
table.table thead tr {
  height: 30px;
  min-height: 30px;
  max-height: 30px;
}
table.table thead tr th {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.8em;
  color: #9B9B9B;
  border: none;
  height: 1.42857143em;
  padding-top: 0.35714286em;
  padding-bottom: 0.35714286em;
}
table.table thead tr th:first-child {
  padding-left: 40px;
}
table.table thead tr th:last-child {
  padding-right: 40px;
}
table.table thead tr th:last-child.sorting:after {
  right: 2.5em;
}
table.table tbody tr {
  border-bottom: 1px solid #EBEBEB;
}
table.table tbody tr td {
  height: 2.85714286em;
  vertical-align: middle;
}
table.table tbody tr td:first-child {
  padding-left: 40px;
}
table.table tbody tr td:last-child {
  padding-right: 40px;
}
table.table tbody tr td .progress {
  margin-bottom: 0;
}
table.table tbody tr td.ellipsis {
  max-width: 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
table.table tbody tr td.ellipsis > div,
table.table tbody tr td.ellipsis > a {
  max-width: 100%;
  float: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
table.table tbody tr td.ellipsis > a {
  display: block;
}
table.table tbody tr td.center {
  text-align: center;
}
table.table tbody tr td .btn-group {
  min-width: 120px;
  white-space: nowrap;
}
table.table tbody tr td .btn-group .tr-btn {
  position: initial;
  float: none;
  height: auto;
}
table.table tbody tr td .btn-group .dropdown-toggle {
  margin-right: 0;
}
table.table tbody tr td .btn-group .dropdown-menu {
  min-width: auto;
  border-radius: 0;
}
table.table tbody tr td div.center span {
  width: auto;
}
table.table tbody tr td.date {
  min-width: 100px;
}
table.table tbody tr:last-of-type {
  border-bottom: none;
}
table.table .issues-table-body tr td:first-child {
  padding-left: 0 !important;
}
table.table .issues-table-body tr td:first-child a.btn {
  float: left;
  padding: 4px 10px;
  margin-left: 5px;
}
table.contacts-table tbody tr td:first-child,
table.small-icon-table tbody tr td:first-child {
  min-width: 140px;
}
table.contacts-table tbody tr td:first-child a.btn,
table.small-icon-table tbody tr td:first-child a.btn {
  padding: 4px 10px;
}
table.contacts-table tbody tr td:first-child button.btn,
table.small-icon-table tbody tr td:first-child button.btn {
  padding: 4px 10px;
}
.table-bottom {
  margin-top: 2.14285714em;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.table-bottom .dataTables_length {
  position: absolute;
  left: 0;
  top: 4px;
}
.table-responsive {
  border: 0 !important;
}
.tables-above {
  display: none;
}
.tables-below {
  margin-top: 25px;
}
.tables-below select {
  min-height: 36px;
}
.tables-below .dataTables_length {
  display: inline-block;
  float: left;
  width: 25%;
}
.tables-below .dataTables_paginate {
  display: inline-block;
  text-align: center;
  float: left;
  width: 50%;
}
.tables-below .dataTables_paginate .pagination .paginate_button a {
  cursor: pointer;
  margin-left: 0.71428571em;
  border-radius: 5px;
}
.tables-below .dataTables_paginate .pagination .paginate_button.active a {
  background-color: transparent;
  color: #180124;
  border-color: #180124;
}
.tables-below .dataTables_paginate .pagination .paginate_button.disabled a {
  cursor: not-allowed;
  opacity: 0.5;
}
@media screen and (max-width: 767px) {
  .tables-below .dataTables_length {
    display: none;
  }
  .tables-below .dataTables_paginate {
    text-align: center;
    width: 100%;
  }
}
.table-buttoned tr > td:last-of-type {
  text-align: right;
  padding-right: 0;
}
div.dataTables_processing {
  width: 100%;
  height: 100%;
  min-height: 50px;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin-top: 0;
  margin-left: 0;
  z-index: 10;
  background: -moz-radial-gradient(center, ellipse cover, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  /* ff3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, #ffffff), color-stop(100%, rgba(255, 255, 255, 0)));
  /* safari4+,chrome */
  background: -webkit-radial-gradient(center, ellipse cover, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  /* safari5.1+,chrome10+ */
  background: -o-radial-gradient(center, ellipse cover, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  /* opera 11.10+ */
  background: -ms-radial-gradient(center, ellipse cover, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  /* ie10+ */
  background: radial-gradient(ellipse at center, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  /* w3c */
}
div.dataTables_processing img {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
.sst-panel .dataTables_wrapper {
  position: relative;
  margin-left: -2.85714286em;
  margin-right: -2.85714286em;
  overflow-x: auto;
  min-height: 0.01%;
  margin-bottom: 15px;
}
.sst-panel .dataTables_wrapper .tables-below,
.sst-panel .dataTables_wrapper .tables-above {
  padding-left: 40px;
  padding-right: 40px;
}
.row-danger {
  background-color: #FE5E46;
}
.selectize-control.single .selectize-input:after,
.selectize-control.single .selectize-input.dropdown-active:after {
  border: solid #9B9B9B;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-top: -5px;
}
.selectize-control .selectize-input {
  border-radius: 0;
}
.fill-space {
  flex: 1 0 auto;
}
