table {
  &.dataTable {
    margin-top: 0 !important;
    margin-bottom: 30px !important; // to make dropdown fit into the table
    padding-bottom: 10px; //to make sure there won't appear a scrollbar when clicking any options.

    > thead {
      > tr {
        > th {
          padding-right: 25px;
          vertical-align: middle;
        }
      }

      .sorting, .sorting_asc, .sorting_desc {
        &:after {
          top: 50%;
          transform: translateY(-50%);
          opacity: 0.6;
        }
      }
    }

    .dataTables_empty {
      text-align: center;
      opacity: 0.5;
    }
  }

  &.table {
    a {
      color: @colors-primary;
    }

    thead {
      background-color: @color-light;

      tr {
        height: 30px;
        min-height: 30px;
        max-height: 30px;

        th {
          font-weight: bold;
          text-transform: uppercase;
          font-size: 0.8em;
          color: @color-gray;
          border: none;
          height: @gutter;
          padding-top: @gutter/4;
          padding-bottom: @gutter/4;

          &:first-child {
            padding-left: @space;
          }

          &:last-child {
            padding-right: @space;

            &.sorting:after {
              right: 2.5em;
            }
          }
        }
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid @color-light-gray;

        td {
          height: @gutter * 2;
          vertical-align: middle;
          //border-top: none; /* fixes first body border */

          &:first-child {
            padding-left: @space;
          }

          &:last-child {
            padding-right: @space;
          }

          .progress {
            margin-bottom: 0;
          }

          &.ellipsis {
            max-width: 1px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            > div, > a {
              max-width: 100%;
              float: left;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            > a {
              display: block;
            }
          }

          &.center {
            text-align: center;
          }

          .btn-group {
            min-width: 120px;
            // Start fix to force buttons side-by-side in tables
            white-space: nowrap;

            .tr-btn {
              position: initial;
              float: none;
              height: auto;
            }
            // End fix to force buttons side-by-side in tables

            .dropdown-toggle {
              margin-right: 0;
            }

            .dropdown-menu {
              min-width: auto;
              border-radius: 0;
            }
          }

          div.center {
            span {
              width: auto;
            }
          }

          &.date {
            min-width: 100px;
          }
        }

        &:last-of-type {
          border-bottom: none;
        }
      }
    }

    .issues-table-body{
      tr{
        td:first-child{
          padding-left: 0 !important;

          a.btn{
            float: left;
            padding: 4px 10px;
            margin-left: 5px;
          }
        }
      }
    }
  }


  &.contacts-table, &.small-icon-table{
    tbody{
      tr{
        td:first-child{
          min-width: 140px;

          a.btn{
            padding: 4px 10px;
          }

          button.btn{
            padding: 4px 10px;
          }
        }
      }
    }
  }
}

.table-bottom {
  margin-top: @gutter * 1.5;
  position: relative;
  display: flex;
  flex-direction: row;

  justify-content: center;

  .dataTables_length {
    position: absolute;
    left: 0;
    top: 4px;
  }
}

.table-responsive {
  border: 0 !important;
}

& .tables-above {
  display: none; // hide DataTables top bar
}

& .tables-below {
  margin-top: 25px;

  select {
    min-height: 36px;
  }

  & .dataTables_length {
    display: inline-block;
    float: left;
    width: 25%;
  }

  & .dataTables_paginate {
    display: inline-block;
    text-align: center;
    float: left;
    width: 50%;

    .pagination {
      .paginate_button {
        a {
          cursor: pointer;
          margin-left: @gutter/2;
          border-radius: @radius-small;
        }
        &.active a {
          background-color: transparent;
          color: @colors-primary;
          border-color: @colors-primary;
        }
        &.disabled a {
          cursor: not-allowed;
          opacity: 0.5;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .tables-below .dataTables_length {
    display: none;
  }
  .tables-below .dataTables_paginate {
    text-align: center;
    width: 100%;
  }
}

.table-buttoned {
  tr > td:last-of-type {
    text-align: right;
    padding-right: 0;
  }
}

div.dataTables_processing {
  width: 100%;
  height: 100%;
  min-height: 50px;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin-top: 0;
  margin-left: 0;
  z-index: 10;

  background: -moz-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%); /* ff3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(100%, rgba(255, 255, 255, 0))); /* safari4+,chrome */
  background: -webkit-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%); /* safari5.1+,chrome10+ */
  background: -o-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%); /* opera 11.10+ */
  background: -ms-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%); /* ie10+ */
  background: radial-gradient(ellipse at center, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%); /* w3c */

  img {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }
}

.sst-panel {
  .dataTables_wrapper {
    position: relative;
    margin-left: @gutter*-2;
    margin-right: @gutter*-2;
    overflow-x: auto;
    min-height: 0.01%;
    margin-bottom: 15px;

    & .tables-below, & .tables-above {
      padding-left: @space;
      padding-right: @space;
    }
  }
}

.row-danger {
  background-color: @color-alert;
}