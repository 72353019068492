.selectize-control.single .selectize-input:after, .selectize-control.single .selectize-input.dropdown-active:after {
  border: solid @color-gray;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-top: -5px;
}

.selectize-control {
  .selectize-input {
    border-radius: 0;
  }
}