.btn {
  height: 3rem;
  padding: 0 1.8rem;
  border-radius: 0;
}

.btn-primary {
  display: inline-flex;
  position: relative;
  background-image: linear-gradient(90deg, rgba(128, 0, 255, .5), rgba(255, 0, 235, .5));
  text-align: center;
  align-items: center;
  vertical-align: middle;
  user-select: none;
  border: none;
  text-decoration: none;
  color: #fff;
  background-color: inherit;
  z-index: 0;
  border-radius: 0;

  .fa {
    margin-right: 3px;
  }
}

.btn-primary:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(90deg, @colors-fade-start, @colors-fade-end);
  z-index: -1;
  transition: opacity .5s linear;
  opacity: 1
}

.btn-primary:focus,
.btn-primary:hover {
  outline: 0;
  color: @color-base;
  text-decoration: none
}

.btn-primary:focus:before,
.btn-primary:hover:before {
  opacity: 0
}

@media print, screen and (min-width: 769px) {
  .btn-primary {
    height: 3rem
  }
}

.btn-secondary {
  display: inline-flex;
  position: relative;
  text-align: center;
  align-items: center;
  vertical-align: middle;
  user-select: none;
  text-decoration: none;
  color: @colors-fade-end;
  border: 2px solid @colors-fade-end;
  background-color: inherit;
  border-radius: 0;
}

.btn-secondary:focus,
.btn-secondary:hover {
  outline: 0;
  border-color: @color-base;
  color: @color-base;
  background-color: @colors-contrast-background;
  text-decoration: none;
  box-shadow: 0 0 27px 1px @colors-fade-end
}

.nav-container button.nav-button {
  text-decoration: none;
  color: @colors-fade-end !important;
  border: 2px solid @colors-fade-end !important;
  border-radius: 0;
  padding: inherit;

  &:disabled {
    pointer-events: none;
  }

  &:focus, &:hover {
    outline: 0;
    border-color: @color-base !important;
    color: @color-base !important;
    background-color: @colors-contrast-background !important;
    text-decoration: none;
    box-shadow: 0 0 27px 1px @colors-fade-end

  }
}

ul {
  .paginate_button.disabled a {
    pointer-events: none;
  }

  .paginate_button a {
    border-radius: 0 !important;
    display: inline-flex;
    position: relative;
    height: 1.8rem;
    padding: 0 1.8rem;
    text-align: center;
    align-items: center;
    vertical-align: middle;
    user-select: none;
    text-decoration: none;
    color: @colors-fade-end !important;
    border: 2px solid @colors-fade-end !important;

    &:focus, &:hover {
      outline: 0;
      border-color: @color-base !important;
      color: @color-base !important;
      background-color: @colors-contrast-background !important;
      text-decoration: none;
      box-shadow: 0 0 27px 1px @colors-fade-end

    }
  }
}

@media print, screen and (min-width: 769px) {
  .btn-secondary {
    height: 3rem
  }
}

.btn-primary .icon,
.btn-secondary .icon {
  display: inline-block;
  width: 1.6rem;
  margin-left: -1.2rem;
  margin-right: .6rem
}

.btn-primary.dimmed:not(:hover),
.btn-secondary.dimmed:not(:hover) {
  opacity: .6
}

.btn-mt {
  margin-top: 35px
}

@media print, screen and (min-width: 769px) {
  .btn-mt {
    margin-top: 2rem
  }
}

.btn-mb {
  margin-bottom: 35px
}

@media print, screen and (min-width: 769px) {
  .btn-mb {
    margin-bottom: 2rem
  }
}