.navbar-header button {
  width: 33px;
  height: 33px;
  cursor: pointer;
  pointer-events: auto;
  z-index: 1;
  float: right;
  background: inherit;
  border: 0;
  padding: 0;
}

.sst-panel {
  position: relative;
  padding: 2*@gutter;

  border-radius: 0;
  box-shadow: 30px 30px 50px @colors-shade;

  h2 {
    margin-bottom: 20px;
  }

  .nav-container {
    position: relative;
    width: 100%;
    display: inline-block;
    vertical-align: middle;

    .tabs-wrapper {
      width: 100%;
      float: left;
      overflow: hidden;

      ul {
        &.nav-tabs {
          width: 100%;
          height: 26px;
          border-bottom: 0;
          white-space: nowrap;
          position: relative;
          transition: .5s ease;

          > li {
            float: none;
            display: inline;

            > a {
              font-size: 14px;
              padding: 8px 12px 4px;
              color: @colors-dim;
              border: none;
              display: inline;

              &:hover {
                color: @colors-text-secondary;
                background: none;
                border: none;
              }
            }

            &.active {
              a {
                border: none;
                color: @colors-text-accent;
                border-bottom: 2px solid @colors-text-accent;
              }
            }
          }
        }
      }
    }

    button.nav-button {
      position: absolute;
      float: left;

      &.left {
        right: 100%;
      }

      &.right {
        left: 100%;
      }

      &[disabled] {
        opacity: 0.3;
      }

      width: @space;
      margin: 0;

      i {
        vertical-align: middle;
      }
    }
  }
}

header {
  padding: 15px 0;

  .branding-wrapper {
    text-decoration: none;

    &:hover{
      text-decoration: none;
    }

    .branding-logo {
      max-height: 80px;
      overflow: hidden;
      margin-top: -20px;
    }

    .branding-text {
      font-size: 32px;
      font-weight: 500;
      color: #2c0648;
    }
  }

  & ul {
    &.nav {
      & li {
        color: @color-gray;
        &.dropdown {
          & span:first-child {
            padding-right: 15px;
          }
        }
      }
    }
  }
  & h3 {
    color: @color-dark;
  }
}

@media screen and (max-width: 767px) {
  header ul.nav li.dropdown span:first-child {
    display: none;
  }
}

footer {
  text-align: center;
  padding: 25px 0;
}

[data-letters]:before {
  content: attr(data-letters);
  display: inline-block;
  font-size: 0.8em;
  width: 2.8em;
  height: 2.8em;
  line-height: 2.8em;
  text-align: center;
  border-radius: 50%;
  background: @color-avatar-background;
  vertical-align: middle;
  margin-right: 1em;
  color: white;
}

.sidebar-nav {

  ul {
    list-style-type: none;
    font-weight: 400;
  }

  li {
    padding: 8px 0;

    & a {
      text-decoration: none;
      padding: 0 10px 5px 10px;

      & i.fa {
        padding-right: 20px;
        display: inline-block;
        text-align: center;
        width: 30px;
        font-size: 16px;

        &:active {
          color: @colors-text-accent;
        }
      }

      &.active {
        color: @colors-text-accent;
        border-bottom: 2px solid @colors-text-accent;

        & i.fa {
          color: @colors-text-accent;
        }

      }

      &:hover {
        color: @colors-text-secondary;

        & i.fa {
          color: @colors-text-secondary;
        }
      }


    }

    &.header {
      color: @color-darkest-gray;
      font-weight: 500;
      &:not(:first-child) {
        padding-top: 25px;
      }
    }
  }
}

.pagehead {
  background-color: @color-light;
  margin-left: @gutter*-2;
  margin-right: @gutter*-2;

  padding: @space;

  .top-buttons {
    width: 100%;

    a {
      margin: 5px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &.table-only {
    padding-top: 0;
    padding-bottom: 0;
    //height: @gutter * 1.6;
    height: 0;
    overflow: hidden;
  }
}

/**
 * Avatar sizes
 */
.avatar-l {
  height: @large;
  width: @large;
}

.alert {
  border: none;
  border-radius: 0;
}