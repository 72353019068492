.progress {
  height: 30px;
  border-radius: 15px;

  .progress-bar {
    font-size: 16px;
    line-height: 30px;
    padding: 0 8px;

    &.progress-bar-success {
      background-color: @colors-primary;
    }
  }
}