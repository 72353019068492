/**
 * Normalizations
 * base
 */

button,
input,
select,
textarea {
    border-radius: 0;
    color: inherit;
    font-family: @font-primary;
    font-weight: 400;
    max-width: 100%;
    outline: none;
    padding-bottom: (@gutter/4);
}

button,
input[type=text],
textarea {
    appearance: none;
}

html, body {
    height: 100%;
    background-color: @color-base;
    color: @color-dark;
    font-family: @font-primary;
    font-size: @font-size;
}
