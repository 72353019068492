@tooltip-color: #0B292D;
@tooltip-border-color: #cecece;
@tooltip-bg: white;
@tooltip-opacity: 1;

.tooltip {
  border-radius: 0;

  .tooltip-inner {
    padding: 10px;
    border: 1px solid @tooltip-border-color;
    background: @tooltip-bg;
    color: @tooltip-color;
  }

  .tooltip-arrow {
    background: white;
    width: 12px;
    height: 12px;
    border-width: 0 !important;
    border-style: solid !important;
    border-bottom-width: 1px !important;
    border-right-width: 1px !important;
    border-color: @tooltip-border-color !important;
  }

  &.in {
    opacity: 1;
  }

  &.top .tooltip-arrow, &.bottom .tooltip-arrow {
    margin-left: -6px !important;
  }

  &.left .tooltip-arrow, &.right .tooltip-arrow {
    margin-top: -6px !important;
  }

  &.top .tooltip-arrow {
    bottom: -1px;
  }

  &.bottom .tooltip-arrow {
    top: -1px;
  }

  &.top .tooltip-arrow,
  &.top-left .tooltip-arrow,
  &.top-right .tooltip-arrow {
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Safari */
    transform: rotate(45deg);
  }

  &.bottom .tooltip-arrow,
  &.bottom-left .tooltip-arrow,
  &.bottom-right .tooltip-arrow {
    -ms-transform: rotate(-135deg); /* IE 9 */
    -webkit-transform: rotate(-135deg); /* Safari */
    transform: rotate(-135deg);
  }

  &.right .tooltip-arrow {
    left: -1px;
    -ms-transform: rotate(135deg); /* IE 9 */
    -webkit-transform: rotate(135deg); /* Safari */
    transform: rotate(135deg);
  }

  &.left .tooltip-arrow {
    right: -1px;
    -ms-transform: rotate(-45deg); /* IE 9 */
    -webkit-transform: rotate(-45deg); /* Safari */
    transform: rotate(-45deg);
  }
}